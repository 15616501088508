:root {
  --white-color: #ffffff;
  --black-color: #000000;
  --primary-blue: #1e88e5;
  --primary-yellow: #ffd026;
  --primary-green: #16a75c;
  --primary-gray: #616161;
  --secondary-color-blue: #1565c0;
  --secondary-color-yellow: #ffda4f;
  --secondary-color-green: #70cd94;
  --secondary-color-gray: #bdbdbd;
  --section-bg-color: #f0f8ff;
  --section-bg-color-blue: #e3f2fd;
  --custom-btn-bg-color: #008444;
  --custom-btn-bg-hover-color: #1565c0;
  --dark-color: #000000;
  --p-color-black: #000000;
  --p-color-white: #ffffff;
  --border-color: #1e88e5;
  --link-hover-color: #1e88e5;

  --background-color-gray: #fafafa;

  --body-font-family: "Montserrat-Regular", sans-serif;
  --title-font-family: "Lato-Regular", sans-serif;

  --h1-font-size: 58px;
  --h2-font-size: 48px;
  --h3-font-size: 32px;
  --h4-font-size: 28px;
  --h5-font-size: 24px;
  --h6-font-size: 21px;
  --p-font-size: 19px;
  --p-font-size-second: 14px;
  --menu-font-size: 17px;
  --btn-font-size: 18px;
  --copyright-font-size: 16px;

  --border-radius-large: 100px;
  --border-radius-medium: 20px;
  --border-radius-small: 10px;

  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --header-height: 3.5rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --first-color: hsl(14, 98%, 50%);
  --black-color: hsl(0, 0%, 0%);
  --black-color-light: hsl(0, 0%, 40%);
  --white-color: hsl(0, 0%, 95%);
  --title-color: hsl(0, 0%, 0%);
  --text-color: hsl(0, 0%, 35%);
  --text-color-light: hsl(0, 0%, 64%);
  --body-color: hsl(0, 0%, 87%);
  --container-color: hsl(0, 0%, 83%);

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Bai Jamjuree", sans-serif;
  --biggest-font-size: 2.5rem;
  --h1-font-size: 1.75rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /*========== Font weight ==========*/
  --font-regular: 400;
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-bold: 700;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

/* Merriweather */
@font-face {
  font-family: "MerriweatherSans-Regular";
  src: local("MerriweatherSans"),
    url(./assets/fonts/MerriweatherSans/MerriweatherSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "MerriweatherSans-Light";
  src: local("MerriweatherSans"),
    url(./assets/fonts/MerriweatherSans/MerriweatherSans-Light.ttf) format("truetype");
}

/* Lato */
@font-face {
  font-family: "Lato-Regular";
  src: local("Lato"),
    url(./assets/fonts/Lato/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Light";
  src: local("Lato"), url(./assets/fonts/Lato/Lato-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato"), url(./assets/fonts/Lato/Lato-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-BoldItalic";
  src: local("Lato"),
    url(./assets/fonts/Lato/Lato-BoldItalic.ttf) format("truetype");
}

/* Montserrat */
@font-face {
  font-family: "Montserrat-Black";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-BlackItalic";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat/Montserrat-BlackItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: local("Montserrat-Bold"),
    url(./assets/fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-BoldItalic";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat/Montserrat-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: local("Montserrat-ExtraBold"),
    url(./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBoldItalic";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url(./assets/fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}

body {
  font-family: "Montserrat-Regular" !important;
}

b,
strong {
  font-weight: bold !important;
}

.container {
  font-family: "Montserrat-Regular" !important;
}

main {
  min-height: 120vh;
}

.txt:first-of-type::first-letter {
  position: relative;
  font-size: 2.5em;
  float: left;
  line-height: 1;
  color: var(--black-color);
  padding: 0 4px 2px 0;
  font-weight: var(--font-weight-bold);
}

/* .txtTittle {
  font-size: 50px;
  font-family: arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(#3a92f7, #283552);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  color: #e5e9f1;
  font-weight: 700;
} */
.flyout {
  /* display: flex; */
  /* flex-direction: column; */
  /* min-height: 100vh; */
  /* justify-content: space-between; */
}

/* NAVBAR */

.navbar-custom {
  top: 0;
  backdrop-filter: blur(15px);
  position: fixed;
  padding: 20px 0;
  height: 5rem;
  z-index: 1030;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
  border: none !important;
}

.navbar-custom li {
  color: #fff;
}

.navbar-custom:hover {
  background: rgb(13, 71, 161);
  background: linear-gradient(90deg,
      rgba(13, 71, 161, 1) 0%,
      rgba(66, 165, 245, 1) 100%);
  border: none !important;
}

.navbar-custom.scrolled {
  background: rgb(13, 71, 161);
  background: linear-gradient(90deg,
      rgba(13, 71, 161, 1) 0%,
      rgba(66, 165, 245, 1) 100%);
}

.nav-disdik .active {
  font-weight: bold !important;
  color: #0b5789 !important;
}

.navbar-content-custom {
  width: 100%;
  display: flex;
  align-items: center;
}

.navbar-content-custom .logo {
  margin-right: auto;
}

.navbar-content-custom .navbar-menu-custom {
  margin: 0;
  list-style: none;
  gap: 0.2rem;
}

.navbar-content-custom ul li {
  cursor: pointer;
  margin-left: 15px;
  border-radius: 5px;
  font-size: var(--menu-font-size);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.5px;
  transition: 0.2s ease;
  border: none !important;
}

.navbar-content-custom ul li.navbar-menu-item-custom:hover {
  background-color: var(--custom-btn-bg-hover-color) !important;
  border: none !important;
}

.navbar-content-custom ul li.navbar-menu-item-custom .icon {
  margin-left: 15px;
  font-size: 14px;
}

.navbar-content-custom .logo {
  width: 6rem;
}

.navbar-content-custom .logo img {
  width: 100%;
}

.navbar-menu-custom {
  display: flex;
}

.navbar-submenu-custom {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background: rgb(13, 71, 161);
  background: linear-gradient(90deg,
      rgba(13, 71, 161, 1) 0%,
      rgba(66, 165, 245, 1) 100%);
  width: 100%;
  border-top: 1px solid #fafafa;
  padding: 2rem;
  cursor: default;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
}

.navbar-submenu-custom.show {
  opacity: 1;
  visibility: visible;
}

.navbar-submenu-header-custom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar-submenu-title {
  font-size: var(--h5-font-size);
}

.navbar-submenu-close {
  cursor: pointer;
}

.navbar-submenu-content-custom {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 1.5rem;
  opacity: 0;
  transition: opacity 0.7s ease;
  width: 100%;
  padding: 0;
}

.navbar-submenu-content-custom.fade-in {
  opacity: 1;
}

.navbar-submenu-content-custom li {
  list-style: none;
  margin: 0 !important;
  padding: 20px 0px 20px 0px;
  border-radius: 15px !important;
  transition: 0.2s ease;
  display: flex;
}

.navbar-submenu-content-custom li a {
  color: inherit;
  display: flex;
  flex-direction: row;
}

.navbar-submenu-content-custom li a .icon-navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50px;
  max-height: 50px;
  background-color: var(--white-color);
  border-radius: 10px;
}

.navbar-submenu-content-custom li a .text {
  margin-left: 15px;
}

.navbar-submenu-content-custom li:hover a .text {
  color: var(--primary-yellow);
  transition: 0.2s ease;
}

.navbar-submenu-content-custom li a .icon-navbar .icon {
  color: var(--primary-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 2rem;
  width: 100%;
  height: 100%;
}

.navbar-submenu-content-custom li a .desc {
  font-size: 12px;
  color: #bbdefb;
}

.navbar-submenu-content-custom li a:hover {
  color: var(--white-color);
}

.navbar-submenu-content-custom li span .icon-navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 50px;
  max-height: 50px;
  background-color: var(--white-color);
  border-radius: 10px;
}

.navbar-submenu-content-custom li span {
  color: inherit;
  display: flex;
  flex-direction: row;
}

.navbar-submenu-content-custom li span .text {
  margin-left: 15px;
}

.navbar-submenu-content-custom li:hover span .text {
  color: var(--primary-yellow);
  transition: 0.2s ease;
}

.navbar-submenu-content-custom li span .icon-navbar .icon {
  color: var(--primary-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 2rem;
  width: 100%;
  height: 100%;
}

.navbar-submenu-content-custom li span .desc {
  font-size: 12px;
  color: #bbdefb;
}

.navbar-submenu-content-custom li span:hover {
  color: var(--white-color);
}

nav .show .dropdown-menu {
  min-width: 280px;
}

/* END NAVBAR */

/* TAMPILAN HOME */
.col-separator {
  cursor: pointer;
  position: relative;
  height: 300px;
  padding-left: 0px !important;
  background-size: cover;
  /* background-image: url("../src/assets/DESIGN\ WEB-12.jpg"); */
}

.c-list-news .overlay-col-separator-yellow {
  background-color: #fec71c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  min-height: 300px;
  width: 100%;
  z-index: 1;
  opacity: 0.6;
  letter-spacing: 3px;
  transition: 0.5s cubic-bezier(1, 0.01, 0.54, 0.55);
}

.overlay-col-separator-yellow:hover {
  background-color: transparent;
}

.c-list-news .overlay-col-separator-blue {
  background-color: #1e88e5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  min-height: 300px;
  width: 100%;
  z-index: 1;
  opacity: 0.6;
  letter-spacing: 3px;
  transition: 0.5s cubic-bezier(1, 0.01, 0.54, 0.55);
}

.overlay-col-separator-blue:hover {
  background-color: transparent;
}

.c-list-news .overlay-col-separator-green {
  background-color: #16a75c;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  min-height: 300px;
  width: 100%;
  z-index: 1;
  opacity: 0.6;
  letter-spacing: 3px;
  transition: 0.5s cubic-bezier(1, 0.01, 0.54, 0.55);
}

.overlay-col-separator-green:hover {
  background-color: transparent;
}

.col-separator-subtitle {
  z-index: 3;
  color: white;
  text-align: center;
  font-weight: 700;
  padding: 10px;
  text-shadow: 3px 3px 7px rgba(68, 68, 68, 0.84);
}

/* END TAMPILAN HOME */

.padding-null {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.hrWhite {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #0d47a1;
  margin: 20px 0px 0px 0px;
  padding: 0;
}

/* .select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}

.select select {
  font-family: "Arial";
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 12px 18px;
  outline: 0;
  border: 1px solid #000000;
  border-radius: 8px;
  background: #ffffff;
  color: #222222;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select select::-ms-expand {
  display: none;
}

.select select:hover,
.select select:focus {
  color: #000000;
  background: #ffffff;
}

.select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.select_arrow {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 0px;
  height: 0px;
  border: solid #7b7b7b;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.select select:hover ~ .select_arrow,
.select select:focus ~ .select_arrow {
  border-color: #000000;
}

.select select:disabled ~ .select_arrow {
  border-top-color: #cccccc;
} */

/* TAMPILAN BERITA */

.subhead-news {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subhead-news .badge-comments {
  display: flex;
  background-color: #e9e9e9;
  margin-bottom: 10px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  font-size: 14px;
  color: #4e4e4e !important;
  transition: 1s;
}

.subhead-news .badge-comments:hover {
  display: flex;
  cursor: pointer;
  background-color: #f7f7f7;
  margin-bottom: 10px;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  font-size: 14px;
  color: #3d3d3d !important;
  transition: 1s;
}

.comment-section {
  display: block;
}

.comment-section .comment-head {
  font-size: 24px;
  letter-spacing: 2px;
  font-weight: 600;
  padding: 0px 0px 0px 10px;
  color: #7c7c7c;
  background-color: #d6d6d6;
  border-bottom: solid #8d8d8d 1px;
}

.comment-section .comment-form {
  margin: 18px 0px 0px 0px;
  /* width: 100%;
	margin: 18px 0px 0px 0px;
	border: solid #4e4e4e 1px;
	border-radius: 8px;
	min-height: 10vh; */
}

.comment-section .no-comments {
  text-align: center;
  margin: 24px 0px 0px 0px;
  padding: 10px 0px 0px 0px;
  border: solid #8d8d8d 0.2px;
  background-color: #d6d6d6;
  min-height: 80px;
}

.comment-section .true-comments {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 10px 0px;
  padding: 10px 0px 10px 10px;
  border-bottom: solid #e7e7e7 0.2px;
  background-color: #f8f8f8;
  min-height: 80px;
}

.comment-section .true-comments .head {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.comment-section .true-comments .head .image-static {
  background-color: #7b7b7b;
  padding: 15px;
  color: white;
  border-radius: 10px;
  text-align: center;
  min-width: 50px;
  transition: 99000ms;
}

.comment-section .true-comments .head .head-title {
  color: rgb(0, 93, 233);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.comment-section .true-comments .head .head-subtitle {
  font-size: 12px;
  color: rgb(71, 71, 71);
}

.comment-section .true-comments .comment {
  margin: 5px 0px 0px 0px;
  letter-spacing: 0.7px;
  font-size: 14px;
}

.comment-section .true-comments .footer {
  cursor: pointer;
  color: #3c72c4;
}

.comment-section .true-comments .footer:hover {
  color: #5691e9;
}

.title-detail-content h2 {
  font-family: "Montserrat-Light";
  color: #164d9f;
  letter-spacing: 0.3rem;
  font-size: 3rem;
}

.subtitle-detail-content {
  font-family: "Montserrat-Light";
  font-size: 0.9rem;
  color: #7c7c7c;
}

.c-body-berita-terkini {
  min-height: 40rem;
  /* margin: 10px; */
  display: flex;
  border-radius: 12px;
  align-items: flex-end;
  justify-content: flex-start;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-body-berita-terkini .c-head-berita-terkini {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  border-radius: 12px;
  padding: 20px;
  color: #fff;
  background-color: #000000bd;
}

.c-body-berita-terkini .c-head-berita-terkini p {
  font-size: 14px;
}

.tabs-menu-custom {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.tabs-content-custom {
  display: flex;
  flex-direction: column;
}

.tabsInfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 1rem;
  padding: 0.3rem;
  overflow: hidden;
  font-size: 18px;
  color: #9e9e9e;
  font-weight: bold;
}

.tabInfo {
  padding: 8px 8px;
  color: #9e9e9e;
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-bottom: 4px solid #e0e0e0;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.2s ease;
}

.tabInfo.active {
  color: var(--primary-blue);
  background-color: #ffff;
  border-bottom: 4px solid var(--primary-blue);
  font-family: "Montserrat-Bold";
}

.tab2Info.active {
  color: var(--text-primary);
  border-bottom: 4px solid var(--info-custom);
  font-weight: bold;
  font-family: "Montserrat-Bold";
}

.tab-label {
  font-size: 11px;
  /* Atur ukuran teks sesuai kebutuhan */
  color: #333;
  /* Warna teks */
  padding: 10px;
  /* Padding untuk label */
  cursor: pointer;
  /* Ubah cursor menjadi pointer */
}

.tab-label:hover {
  color: #007bff;
  /* Warna teks saat hover */
  text-decoration: underline;
  /* Garis bawah saat hover */
}

.title-container {
  display: flex;
  align-items: center;
}

.btn-custom-home {
  /* color: var(--section-bg-color); */
  border: 1px solid var(--border-color);
  box-shadow: none;
  /* display: inline-block; */
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition: 0.2s ease;
  border-radius: 10px;
}

.btn-custom-home:hover {
  background-color: var(--primary-custom);
  border: 1px solid var(--primary-custom);
  color: #fff;
  box-shadow: none;
}

.btn-custom-home:hover:before {
  transform: scaleX(1);
}

.boxContent {
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  /* min-height: 200px; */
  padding: 10px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.2s ease;
}

.boxContent:hover {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  color: var(--text-primary);
  background-color: var(--primary-light-custom);
}

.boxContent .titleNews {
  font-size: 16px;
  font-weight: bold;
}

.text__details {
  display: flex;
}

.text__detail {
  font-size: 12px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.text__detail__tag {
  font-size: 12px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  border-right: 0.15em solid #fff;
  padding: 0 1em;
}

.news-card-skeleton {
  min-width: 320px;
  min-height: 400px;
  margin: 0px 15px 15px 15px;
  border-radius: 4px;
}

.news-text {
  font-family: "Lato-Bold";
  font-size: 18px;
  margin-top: -50px;
}

.pagination-news {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 20px;
  padding: 0;
}

.pagination-news a {
  cursor: default;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #006b42;
  color: #000 !important;
  margin: 4px;
}

.pagination-news li:not(.disabled) a:hover {
  background-color: bisque;
  cursor: pointer;
}

.pagination-news li {
  display: flex;
  justify-content: center;
}

.pagination-news .page-pag {
  display: flex;
  justify-content: center;
  width: 50px;
}

.pagination-news .previous-pag,
.pagination-news .next-pag {
  display: flex;
  justify-content: center;
  width: 70px;
}

.pagination-news li a {
  font-weight: bold;
  width: 100%;
  text-align: center;
}

.pagination-news li.active a {
  color: #fff !important;
  background: #00578a;
}

.pagination-news li.disabled a {
  pointer-events: none;
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.events-separator {
  cursor: pointer;
  position: relative;
  height: 300px;
  padding-left: 0px !important;
  background-size: cover;
}

.events-date {
  font-size: 12px;
  color: #525252;
}

.events-date-1 {
  font-size: 12px;
  color: #525252;
  margin-top: -10px;
}

.events-view {
  font-size: 12px;
  color: #525252;
}

.events-countdown {
  font-size: 25px;
  color: #525252;
}

.news-title {
  font-size: 18px;
}

.news-all {
  font-size: 18px;
}

.news-textTitle1 {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: justify;
  border-bottom: 1px solid rgb(10, 10, 10);
}

/* END TAMPILAN BERITA */

/* CSS Social Media */
.icon-bar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 50%;
  background: rgba(255, 255, 255, 0.1);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: -20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.icon-bar .c-list-sosmed {
  gap: 1;
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  width: 50px !important;
  padding: 8px;
  color: white;
  font-size: 20px;
  transition: all 0.3s ease;
}

/* Style the social media icons with color, if you want */
.icon-bar a:hover {
  /* background-color: #000; */
  transform: scale(1.2);
}

.icon-bar span {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
  border-radius: 8px;
  min-width: 30px;
  min-height: 30px;
  border: 1px solid #e0e0e0;
  color: #e0e0e0;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .facebook {
    background: #3b5998;
    color: white;
  }

  .twitter {
    background: #55acee;
    color: white;
  }

  .google {
    background: #dd4b39;
    color: white;
  }

  .linkedin {
    background: #007bb5;
    color: white;
  }

  .youtube {
    background: #bb0000;
    color: white;
  }
}

@media screen and (max-width: 600px) {
  .icon-bar {
    display: none;
  }

  .c-akses-cepat {
    display: none !important;
  }

  .news-jargon-text {
    padding-top: 3vh;
    margin-bottom: 50px;
    margin-right: 50px;
    margin-left: 50px;
    font-family: "Montserrat-ExtraBoldItalic";
    font-size: 2.5rem;
    letter-spacing: 1px;
    line-height: 1.3;
    font-weight: 700;
    text-align: right;
    border-bottom: 2px solid #ffffff;
    color: #ffffff;
  }

  .news-jargon-text-tikom {
    font-family: "Montserrat-ExtraBold";
    font-size: 3rem;
    margin-right: 50px;
    margin-left: 50px;
    letter-spacing: 0px;
    word-spacing: 0.2px;
    font-weight: 700;
    text-decoration: none;
    text-align: right;
    margin-top: -40px;
    line-height: 1.3;
    color: #ffffff;
  }

  .font-jargon-empowers-header {
    font-family: "Montserrat-BoldItalic";
    text-align: right;
    margin-right: 50px;
    margin-left: 50px;
  }

  .font-jargon-empowers-header p {
    color: rgb(12, 116, 238);
    font-size: 1.5rem;
    background-color: #ffffff;
    padding-left: 1vw;
    padding-right: 1vw;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 5% 100%);
  }

  .tag-new-news {
    font-family: "Montserrat-Bold";
    font-size: 1rem;
  }

  .font-jargon {
    font-family: "Montserrat-ExtraBoldItalic";
    font-size: 3rem;
    color: #e5e5e5;
  }

  .font-jargon-tikom {
    font-family: "Montserrat-ExtraBold";
    font-size: 3rem;
    color: #e5e5e5;
  }

  .font-jargon-empowers p {
    font-size: 1.5rem;
    background-color: #e5e5e5;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 5% 100%);
  }

  .margin-side {
    margin-left: 3vw;
    margin-right: 3vw;
  }

  header video {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    left: 50%;
    width: auto;
    height: auto;
    z-index: 1;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
}

/* END CSS Jargon */

.indicator-custom {
  cursor: pointer;
  padding: 5px;
  width: 5px;
  text-align: center;
  border: 1px #edf0f2 solid;
  background-color: #c7c7c7;
  margin: 2px;
  border-radius: 8px;
  opacity: 0.5;
  transition: all 0.5s cubic-bezier(1, 0.25, 0, 0.75) 0s;
}

.indicator-custom.active {
  width: 50px;
  border: 1px #edf0f2 solid;
  background-color: #fec71c;
  opacity: 0.9;
}

/* AKSES CEPAT */
.card.card-akses-cepat {
  border: 2px solid rgba(212, 212, 212, 0.5);
  box-shadow: none;
  border-radius: 15px;

  padding: 10px;
}

.card.card-akses-cepat p {
  font-size: 12px;
  letter-spacing: 0.5px;
}

.card.card-akses-cepat h4 {
  font-weight: bold;
}

.card.card-akses-cepat h5 {
  font-weight: bold;
}

.card.card-akses-cepat h6 {
  font-weight: bold;
  font-size: 14px;
}

.card.card-akses-cepat .btn-custom-home {
  color: var(--border-color);
  border: 1px solid var(--border-color);
  box-shadow: none;
  /* display: inline-block; */
  transform: perspective(1px) translateZ(0);
  position: relative;

  font-size: 10px;
}

.card.card-akses-cepat .btn-custom-home:hover {
  background-color: var(--primary-custom);
  border: 1px solid var(--primary-custom);
  color: #fff;
  box-shadow: none;
}

.card.card-akses-cepat .btn-custom-home:hover:before {
  transform: scaleX(1);
}

.slider-container-custom {
  display: inline-block;
}

.slide-akses-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.icon-facebook-custom {
  color: #3b5998;
}

.icon-instagram-custom {
  color: #d62976;
}

.icon-twitter-custom {
  color: #1da1f2;
}

.icon-youtube-custom {
  color: #ff0000;
}

.icon-facebook-custom:hover {
  color: #3b5998;
}

.icon-instagram-custom:hover {
  color: #d62976;
}

.icon-twitter-custom:hover {
  color: #1da1f2;
}

.icon-youtube-custom:hover {
  color: #ff0000;
}

.btn-tutup-custom {
  background-color: var(--text-danger);
  color: #fff;
  box-shadow: none;
  border-radius: 10px;
}

.btn-tutup-custom:hover {
  box-shadow: none;
  color: #fff;
}

.btn-tutup-custom:focus {
  box-shadow: none !important;
}

.btn-tutup-custom:active {
  box-shadow: none !important;
}

.side-menu-card .title {
  font-family: "Montserrat-SemiBold";
  font-size: 1.3rem;
}

.side-menu-card .content {
  font-family: "Montserrat-Light";
  font-size: 0.9rem;
  display: block;
}

.side-menu-card .link-more {
  font-family: "Montserrat-Light";
  font-size: 0.8rem;
  font-style: italic;
  color: rgb(250, 78, 78);
}

/* MODAL POP UP */
.popup-modal {
  max-width: 100vh;
  max-height: 80vh;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: none;
  border: none;
  z-index: 999;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  opacity: 1;
  transform: translateY(-50%);
  animation: fade-in-top 0.5s ease-out forwards;
}

@keyframes fade-in-top {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
  }
}

.popup-modal--is-open {
  opacity: 1;
  transform: scale(1);
}

.popup-overlay {
  box-shadow: none;
  border: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 998;
  transition: opacity 0.2s ease-in-out;
  opacity: 2;
}

.popup-overlay--is-open {
  opacity: 1;
  pointer-events: auto;
}

.popup-img {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
  background: #fdc926;
  color: #007bff;
  width: 30px;
  height: 30px;
  overflow: hidden;
  transition: 0.2s linear;
}

.popup-close:hover {
  transform: scale(1.3);
}

/* END POP UP */

.carousel-control-prev-icon {
  background-image: none !important;
}

.carousel-control-next-icon {
  background-image: none !important;
}

/* SAPA DISDIK */
.rdp {
  --rdp-cell-size: 47px !important;
}

.rdp {
  --rdp-cell-size: 70px !important;
  margin: 0px !important;
  margin-bottom: 20px !important;
}

.rdp-months {
  justify-content: center;
  width: 100%;
}

.react-jinke-music-player-custom {
  bottom: 30px !important;
}

.list-audio-sapa-disdik-wrap {
  margin: 0 auto;
  /* margin-top: 30px; */
}

.list-audio-sapa-disdik-wrap .list-audio-sapa-disdik li {
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 14px;
  transition: 0.2s ease;
  border-radius: 10px;
  color: #000;
}

.list-audio-sapa-disdik-wrap .list-audio-sapa-disdik li .main-title-audio {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  font-size: 12px;
}

.list-audio-sapa-disdik-wrap .list-audio-sapa-disdik li .main-title-audio .main-title-audio.active {
  display: inline-block;
  animation: marqueeAnimation 10s linear infinite;
}

@keyframes marqueeAnimation {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

.list-audio-sapa-disdik-wrap .list-audio-sapa-disdik .active {
  background-color: #04a6b3;
  color: #fff;
}

.list-audio-sapa-disdik-wrap .list-audio-sapa-disdik li:hover {
  background-color: #dadada;
  border-radius: 10px;
  /* color: #fff !important; */
}

.list-audio-sapa-disdik-wrap .list-audio-sapa-disdik li .item-audio-sapa-disdik {
  cursor: pointer;
  display: block;
  margin: 0;
  display: flex;
  position: relative;
  height: 20px;
}

.list-audio-sapa-disdik-wrap .list-audio-sapa-disdik li .item-audio-sapa-disdik .num-audio-sapa-disdik {
  padding-left: 21px;
  width: 25px;
}

.list-audio-sapa-disdik-wrap .list-audio-sapa-disdik li .item-audio-sapa-disdik .title-audio-sapa-disdik {
  left: 50px;
  overflow: hidden;
  position: absolute;
  right: 20px;
  text-overflow: ellipsis;
  /* color: #000; */
  white-space: nowrap;
}

.list-audio-sapa-disdik-wrap .list-audio-sapa-disdik li .item-audio-sapa-disdik .length-audio-sapa-disdik {
  padding-left: 21px;
  position: absolute;
  right: 21px;
  top: 0;
}

.equalizer-custom {
  display: flex;
  justify-content: space-around;
}

.cube1 {
  height: 2px;
  width: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #fff;
  animation: jump 1s infinite 0.2s;
}

.cube2 {
  height: 2px;
  width: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #fff;
  animation: jump 1s infinite 0.4s;
}

.cube3 {
  height: 2px;
  width: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #fff;
  animation: jump 1s infinite 0.6s;
}

.cube4 {
  height: 2px;
  width: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #fff;
  animation: jump 1s infinite 0.8s;
}

.cube5 {
  height: 2px;
  width: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #fff;
  animation: jump 1s infinite 1s;
}

.cube1:hover {
  animation: jump 1s infinite;
}

.btn-streaming {
  margin-top: 20px;
  background-color: var(--white-color);
  border-radius: 10px;
  box-shadow: none;
  width: 12rem;
  border: 0;
  font-weight: bold;
}

.btn-streaming .icon {
  margin-right: 5px;
  color: var(--black-color);
}

.btn-streaming span {
  margin-left: 10px;
  color: var(--black-color);
}

.btn-streaming:hover {
  color: var(--primary-blue);
  box-shadow: none;
  border: 0;
}

.btn-streaming:focus {
  box-shadow: none !important;
}

@keyframes jump {
  0% {
    height: 5px;
  }

  50% {
    height: 25px;
  }

  100% {
    height: 5px;
  }
}

/* END SAPA DISDIK */

/* AGENDA */
.border-none {
  border: none;
}

.border-l {
  border-left: 1px solid #e5e5e5;
}

.login-timeline-wrapper {
  height: 250px;
  overflow-y: auto;
}

.login-timeline {
  position: relative;
  padding-left: 40px;
}

.login-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  height: 100%;
  width: 2px;
  background: #009a77;
}

.login-event {
  margin-bottom: 20px;
  position: relative;
}

.login-event .login-date {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #009a77;
  color: #fff;
  text-align: center;
  line-height: 60px;
  position: absolute;
  top: 0;
  left: -28px;
  font-weight: bold;
  display: none;
}

.login-timeline .login-active .login-date {
  display: block !important;
  /* Menampilkan elemen .date jika parent memiliki kelas .active */
}

.login-event .login-description {
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rbc-toolbar .rbc-toolbar-label {
  text-align: left !important;
}

.rbc-toolbar-label {
  color: var(--primary-custom);
  font-size: 26px;
  display: flex;
  gap: 20px;
  font-weight: bold;
  vertical-align: middle;
  align-items: center;
}

.rbc-toolbar-label svg {
  cursor: pointer;
}

.calendar-ds .rbc-event {
  background-color: var(--primary-custom);
  margin-top: 20px;
  width: 90%;
  margin-left: 10px;
  /* border: 1px solid red !important; */
}

.calendar-ds .rbc-event .rbc-event-content {
  height: 100%;
  font-size: 15px;
  text-align: center;
}

.calendar-ds .rbc-event:focus:active {
  /* border: 1px solid red !important; */
}

.calendar-ds .rbc-event:active {
  /* border: 1px solid red !important; */
}

.calendar-ds {
  position: relative;
  width: 100%;
  height: 700px;
  /* height: 600px; */
  /* min-height: 600px;
  height: 100%; */
  z-index: 0;
  font-family: "Montserrat-Regular" !important;
}

.timeline {
  list-style: none;
  max-height: 350px;
  height: 100%;
  overflow-y: auto;
  font-family: "Montserrat-Regular" !important;
}

/* END AGENDA */

.icons {
  margin-right: 3px;
  font-size: 18px;
}

.icons-color {
  color: --c-color-gray;
}

/* Merriweather */
@font-face {
  font-family: "MerriweatherSans-Regular";
  src: local("MerriweatherSans"),
    url(./assets/fonts/MerriweatherSans/MerriweatherSans-Regular.ttf) format("truetype");
}

.c-button-download {
  background-color: var(--primary-custom);
  border-radius: 10px;
  box-shadow: none;
  padding: 10px;
  border: 0;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  box-shadow: 0 0 #0000, 0 0 #0000, 0px 12px 22px rgba(2, 43, 85, 0.08);
}

.c-button-download .icon {
  margin-right: 5px;
}

.c-button-download:hover {
  box-shadow: none;
  border: 0;
}

@keyframes pulsing {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

@media (min-width: 640px) {
  .timeline>li {
    overflow: hidden;
    margin: 0;
    position: relative;
  }

  .timeline-date {
    width: 100px;
    float: left;
    margin-top: 20px;
    font-weight: bold;
    color: #9e9e9e;
  }

  .timeline-date-active {
    width: 100px;
    float: left;
    margin-top: 20px;
    font-weight: bold;
    color: var(--primary-custom);
  }

  .timeline-content-not-started-yet {
    width: 75%;
    float: left;
    border-left: 3px #e0e0e0 solid;
    padding-left: 30px;
  }

  .timeline-content-not-started-yet:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #9e9e9e;
    position: absolute;
    left: 92px;
    top: 24px;
    border-radius: 100%;
    /* transform: translate(-50%, -50%); */
  }

  .timeline-content {
    width: 75%;
    float: left;
    border-left: 3px var(--blue-outline-custom) solid;
    padding-left: 30px;
  }

  .timeline-content .pulse {
    position: absolute;
    height: 22px;
    width: 22px;
    border-radius: 100%;
    background: var(--primary-custom);
    z-index: 1;
    left: 91px;
    top: 23px;
    animation: pulsing 2.5s ease-in-out;
    animation-iteration-count: infinite;
  }

  .timeline-content .pulse.pulse-delay {
    animation-delay: 0.7s;
  }

  .timeline-content:before {
    content: "";
    width: 20px;
    height: 20px;
    background: var(--primary-custom);
    position: absolute;
    left: 92px;
    top: 24px;
    border-radius: 100%;
  }

  .content-box-pd {
    position: absolute;
    z-index: 1;
    bottom: 7px;
    width: 255px;
    height: 250px;
    color: #fff;
    padding: 0 7px;
    font-family: "Poppins", sans-serif;
    border-radius: 10px 10px 15px 15px;
    backdrop-filter: blur(4px);
    background: rgba(145, 145, 145, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .content-box-pd p {
    color: rgb(34, 34, 34);
    font-size: 13px;
  }

  .c-grid-piradio {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    gap: 1rem;
  }

  @media (min-width: 600px) {
    .c-grid-piradio {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 900px) {
    .c-grid-piradio {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .arrow {
    position: relative;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
    width: 0;
    height: 0;
    border-top: 0.8rem solid transparent;
    border-bottom: 0.8rem solid transparent;
    border-left: 1rem solid #fff;
  }

  .arrow::after {
    transition: transform 0.4s ease-in-out;
    content: "";
    position: absolute;
    background-color: transparent;
    width: 1.5rem;
    height: 2rem;
    top: -1rem;
  }

  .arrow::before {
    transition: all 0.3s ease-out;
    content: "";
    position: absolute;
    background-color: transparent;
    width: 1rem;
    height: 1.6rem;
    top: -0.8rem;
  }
}

/* FOOTER CSS */
footer {
  font-family: "Montserrat-Regular";
  /* background-color: var(--success-custom); */
  background: rgb(13, 71, 161);
  background: linear-gradient(90deg,
      rgba(13, 71, 161, 1) 0%,
      rgba(66, 165, 245, 1) 100%);
  position: relative;
}

footer .c-bg-footer {
  position: relative;
}

footer .titleFooter {
  font-family: "Montserrat-Bold";
  font-size: 16px;
  letter-spacing: 1px;
  word-spacing: 0px;
  margin-bottom: 7px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
}

footer p li {
  font-size: 14px;
  color: #fff;
}

footer a {
  font-size: 14px;
  color: #fff;
}

footer .fab {
  font-size: 14px;
  color: #fff;
}

footer .c-footer-medsos span {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 8px;
  min-width: 40px;
  min-height: 40px;
  border: 1px solid #fff;
  cursor: pointer;
}

footer .c-footer-medsos i {
  font-size: 20px;
  color: #fff;
}

footer .footerCopy {
  font-size: 13px;
  letter-spacing: 0px;
  word-spacing: 0px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
}

footer .c-wrap-footer-sitemap {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  row-gap: 2rem;
}

footer .c-wrap-footer-sitemap span {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
}

footer .c-wrap-footer-sitemap .listContentFooter {
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  font-size: 20px;
  row-gap: 0.4rem;
  margin-top: 10px;
}

footer .c-wrap-footer-sitemap .listContentFooter a {
  font-size: 14px;
  color: #fff !important;
}

footer .c-wrap-footer-sitemap .listContentFooter a:hover {
  color: #fff;
}

.banner-custom {
  width: 100%;
  height: 100%;
  /* background-color: var(--success-custom); */
  background: linear-gradient(90deg,
      rgba(13, 71, 161, 1) 0%,
      rgba(66, 165, 245, 1) 100%);
}

/* BREAD CRUMB */
.breadcrumb-custom {
  height: 100%;
  display: flex;
  align-items: center;
}

.breadcrumb-custom {
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.breadcrumb-content-custom {
  display: flex;
  align-items: center;
}

.breadcrumb-content-custom .icon {
  display: flex;
  align-items: center;
  color: #e0e0e0;
  margin-right: 20px;
}

.breadcrumb-content-custom .link-home {
  margin-right: 20px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.breadcrumb-content-custom .link-home a {
  color: var(--text-warning);
}

.breadcrumb-content-custom .link-home a:hover {
  color: var(--text-warning) !important;
}

.breadcrumb-content-custom .link {
  margin-right: 20px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.banner-header-custom {
  margin-top: 30px;
}

.banner-header-custom .title {
  color: #fff;
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 21px;
  text-transform: uppercase;
  /* line-height: 3rem; */
}

.banner-header-custom .subtitle {
  color: #bbdefb;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 3rem; */
}

.black-and-white {
  filter: grayscale(100%);
}

.section-pencarian-berita {
  margin-top: 30px;
}

.section-pencarian-berita .btn-search-home-custom {
  background-color: var(--primary-blue);
  /* padding: 14px; */
  height: 3rem !important;
  height: 100%;
  border-radius: 10px;
  color: var(--white-color);
  box-shadow: none;
}

.section-pencarian-berita .btn-search-home-custom:focus {
  box-shadow: none !important;
}

.section-pencarian-berita .input-custom {
  /* border-radius: 10px !important;
  width: 100%;
  height: 100%;
  padding: 10px; */
  /* border-radius: 10px !important; */
  padding: 0;
  padding-inline: 20px;
  width: 100%;
  height: 3rem;
  /* padding: 10px; */
  /* border: none !important; */
  border-radius: 10px;
}

.section-pencarian-berita b {
  font-family: "Montserrat-Bold";
}

.section-pencarian-berita strong {
  font-family: "Montserrat-Bold";
}

/* 
.breadcrumb-content-custom .link a:hover {
  color: #fff;
} */

header[data-v-6a01edff] {
  border: none !important;
}

.mobile-navbar {
  background: rgb(13, 71, 161);
  background: -webkit-linear-gradient(90deg,
      rgba(13, 71, 161, 1) 0%,
      rgba(66, 165, 245, 1) 100%);
  background: linear-gradient(90deg,
      rgba(13, 71, 161, 1) 0%,
      rgba(66, 165, 245, 1) 100%);
  /* background-color: var(--success-custom); */
  box-shadow: none;
  -webkit-box-shadow: none;
  /* Vendor Prefix untuk box-shadow */
  position: fixed;
  z-index: 10;
  width: 100%;
}

.navbar-header-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-header-mobile-logo {
  width: 4rem;
  margin-left: 20px;
}

.navbar-header-mobile-logo .logo img {
  width: 100%;
}

.mobile-navbar li {
  color: #fff;
}

.navbar-collapse-mobile {
  /* margin-top: 100px; */
  box-shadow: none;
  -webkit-box-shadow: none;
  /* Vendor Prefix untuk box-shadow */
  background: rgb(13, 71, 161);
  background: -webkit-linear-gradient(90deg,
      rgba(13, 71, 161, 1) 0%,
      rgba(66, 165, 245, 1) 100%);
  background: linear-gradient(90deg,
      rgba(13, 71, 161, 1) 0%,
      rgba(66, 165, 245, 1) 100%);
  /* background-color: var(--success-custom); */
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  /* transition: transform 0.3s; */
  transform: translateX(-100%);
  overflow-y: auto;
  transition: transform 0.3s ease-out, transform 0.3s ease-out;
  z-index: 99;
}

.navbar-collapse-mobile.show {
  transform: translateX(0);
  /* opacity: 1;
  visibility: visible; */
  /* Slide it in when the menu is open */
}

.navbar-collapse-mobile .navbar-nav-mobile {
  margin-top: 4rem;
  padding-inline: 10px;
}

.navbar-collapse-mobile .navbar-nav-mobile .nav-item-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.navbar-collapse-mobile .navbar-nav-mobile .nav-link {
  color: #fff !important;
}

.navbar-collapse-mobile .navbar-nav-mobile .navbar-content-mobile {
  padding-inline: 8px;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.navbar-collapse-mobile .navbar-nav-mobile .navbar-content-mobile li {
  padding: 10px 20px 10px 20px;
  border-left: 2px solid #1976d2;
}

.navbar-collapse-mobile .navbar-nav-mobile .navbar-content-mobile li a {
  color: #fff;
}

.navbar-collapse-mobile .navbar-nav-mobile .navbar-content-mobile li a:hover {
  color: #fff;
}

.navbar-collapse-mobile .navbar-nav-mobile .navbar-content-mobile li span {
  color: #fff;
}

.navbar-collapse-mobile .navbar-nav-mobile .navbar-content-mobile li span:hover {
  color: #fff;
}

.c-wrap-unduh-file {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  border: solid 1px #e0e0e0;
  border-radius: 10px;
}

.c-wrap-unduh-file .c-btn-icons-unduh-file {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 20px;
  margin-right: 20px;
}

/* PPID PROFIL */

.nav-pills-custom .nav-link {
  position: relative;
}

.nav-pills-custom .nav-link span {
  font-size: 12px;
  line-height: 1.5;
}

.nav-pills-custom .nav-link.active {
  color: var(--p-color-white);
  background: var(--primary-blue);
}


.fade-in {
  animation: fadeIn 0.6s ease-in-out;
}

.fade-in-publik {
  opacity: 0;
  animation: fadeIn 3s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.section-heading .left-line {
  width: 3px;
  height: 74px;
  background-color: var(--border-color);
  float: left;
  margin-right: 15px;
}

.section-heading .left-line-lapor {
  width: 3px;
  height: 180px;
  background-color: var(--primary-blue);
  float: left;
  margin-right: 15px;
}

.section-heading h2 {
  font-size: 32px;
  letter-spacing: 0.5px;
  color: var(--p-color-black);
  font-weight: 900;
}

.section-heading h2 em {
  font-style: normal;
  color: var(--p-color-black);
}

.section-heading h4 {
  font-style: var(--body-font-family);
  font-size: var(--h5-font-size);
  color: var(--p-color-black);
  margin-top: 10px;
  font-weight: var(--font-weight-bold);
}

.section-heading ol li {
  font-size: var(--p-font-size);
  letter-spacing: 0.5px;
  color: var(--p-color-black);
  font-weight: var(--font-weight-normal);
}

.section-heading p {
  font-size: var(--p-font-size);
  font-style: var(--body-font-family);
  color: var(--p-color-black);
}

.section-heading p em {
  font-weight: var(--font-weight-normal);
}

.custom-list {
  list-style-type: disc;
}

.custom-text-piradio {
  position: relative;
  text-align: justify;
}

.custom-text-piradio p {
  list-style-type: none;
  font-size: var(--p-font-size);
}

.custom-text-piradio h3 {
  list-style-type: none;
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight-bold);
}

.custom-text-piradio h4 {
  list-style-type: none;
  font-size: var(--h4-font-size);
  font-weight: var(--font-weight-bold);
}

.custom-text {
  position: relative;
  text-align: justify;
}

.custom-text a {
  font-size: var(--p-font-size-second);
  font-weight: var(--font-weight-bold);
}

.custom-text p {
  list-style-type: none;
  font-size: var(--p-font-size-second);
}

.custom-text h4 {
  font-size: var(--h5-font-size);
  font-weight: var(--font-weight-bold);
}

.custom-text h5 {
  font-size: var(--h5-font-size);
  font-weight: var(--font-weight-bold);
}

.custom-text h6 {
  font-size: 18px;
  font-weight: var(--font-weight-medium);
}

.custom-text ol li p {
  list-style-type: none;
  padding-left: 0;
  list-style-type: decimal;
  font-size: var(--p-font-size-second);
  margin-bottom: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Add indicator arrow for the active tab */
@media (min-width: 992px) {
  .nav-pills-custom .nav-link::before {
    content: "";
    display: block;
    border-top: 8px solid transparent;
    border-left: 10px solid #fff;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    opacity: 0;
  }
}

.nav-pills-custom .nav-link.active::before {
  opacity: 1;
}

/* END PROFIL */

/* Carousel Container */

.slider__navi {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  z-index: 999;
}

.slider__navi a {
  display: block;
  height: 6px;
  width: 20px;
  margin: 20px 0;
  text-indent: -9999px;
  box-shadow: none;
  border: none;
  background: rgba(0, 0, 0, 0.2);
}

.slider__navi a.active {
  background: rgba(255, 255, 255, 1);
}

.flex__container {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  -o-justify-content: flex-start;
  justify-content: flex-start;
  height: 100vh;
  width: 100%;
  z-index: 1;
}

.flex__container.flex--active {
  z-index: 2;
}

.text--sub {
  font-size: 18px;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.text--big {
  font-family: "Poppins", sans-serif;
  font-size: 7.5em;
  font-weight: 700;
  line-height: 110px;
  margin-left: -8px;
}

.text--normal {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 22px;
  margin-top: 25px;
}

.text__background {
  font-family: "Poppins", sans-serif;
  position: absolute;
  left: 72px;
  bottom: -60px;
  color: rgba(0, 0, 0, 0.05);
  font-size: 120px;
  font-weight: 700;
}

.flex__item {
  height: 100vh;
  color: #fff;
  transition: transform 0.1s linear;
}

.flex__item--left {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  width: 65%;
  transform-origin: left bottom;
  transition: transform 0.1s linear 0.4s;
  opacity: 0;
  position: relative;
  overflow: hidden;
}

.flex__item--right {
  width: 35%;
  transform-origin: right center;
  transition: transform 0.1s linear 0s;
  opacity: 0;
}

.flex--preStart .flex__item--left,
.flex--preStart .flex__item--right,
.flex--active .flex__item--left,
.flex--active .flex__item--right {
  opacity: 1;
}

/* Piplup */

.flex--piplup .flex__item--left {
  background: #3e9fe6;
}

.flex--piplup .flex__item--right {
  background: #d3eaef;
}

/* Pikachu */

.flex--pikachu .flex__item--left {
  background: #f8d41f;
}

.flex--pikachu .flex__item--right {
  background: #f4ecc5;
}

/* Blaziken */

.flex--blaziken .flex__item--left {
  background: #f64f37;
}

.flex--blaziken .flex__item--right {
  background: #ffebcd;
}

/* Dialga */

.flex--dialga .flex__item--left {
  background: #476089;
}

.flex--dialga .flex__item--right {
  background: #ade8f7;
}

/* Zekrom */

.flex--zekrom .flex__item--left {
  background: var(--primary-green);
}

.flex--zekrom .flex__item--right {
  background: var(--secondary-color-green);
}

.flex__content {
  margin-left: 80px;
  width: 55%;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s linear 0.2s, opacity 0.1s linear 0.2s;
}

.pokemon__img {
  position: absolute;
  bottom: 20px;
  right: 15%;
  max-height: 40vw;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 0.43s 0.6s,
    transform 0.4s 0.65s cubic-bezier(0, 0.88, 0.4, 0.93);
}

/* Animate-START point */

.flex__container .animate--start .flex__content {
  transform: translate3d(0, -200%, 0);
  opacity: 0;
}

.flex__container .animate--start .pokemon__img {
  transform: translate3d(-200px, 0, 0);
  opacity: 0;
}

/* Animate-END point */

.flex__container .animate--end .flex__item--left {
  transform: scaleY(0);
}

.flex__container .animate--end .flex__item--right {
  transform: scaleX(0);
}

.flex__container .animate--end .flex__content {
  transform: translate3d(0, 200%, 0);
  opacity: 0;
}

.flex__container .animate--end .pokemon__img {
  transform: translate3d(200px, 0, 0);
  opacity: 0;
}

.tabs-container {
  margin: 20px auto;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.costum-tabs-container {
  border-radius: 1rem;
  padding: 0.2rem;
  overflow: hidden;
  background-color: var(--white-color);
  box-shadow: 1px 1px 1px #888888;
}

.custom-tabs {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.custom-tabs li {
  position: relative;
  z-index: 1;
  display: flex;
  flex: 1 0 33.33%;
  border-radius: 1rem;
}

.custom-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  flex-grow: 1;
  height: 54px;
  width: 200px;
  padding: 0 2.5rem;
  color: var(--black-color);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
}

.custom-tab.active {
  background-color: #007bff;
  color: #fff;
  transition: color 0.4s ease-in-out;
}

.tab-pane {
  display: none;
  animation: fadeIn 0.5s ease-in-out;
}

.tab-pane-kcd {
  display: none;
  animation: fadeIn 0.5s ease-in-out;
}

.tab-pane-kcd.active {
  display: block;
}

.tab-pane.active {
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.clickable-card {
  display: block;
  max-width: 400px;
  margin-inline: auto;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: none;
  background: #fff;
  transform-origin: center center;
  transition: all 0.4s ease-in-out;
  text-decoration: none;
  color: black;
}

.clickable-card h2,
.clickable-card .read-more {
  transition: color 0.4s ease-in-out;
}

.clickable-card img {
  transform-origin: center;
  transition: transform 0.4s ease-in-out;
}

.clickable-card:hover .read-more,
.clickable-card:focus .read-more,
.clickable-card:hover h2,
.clickable-card:focus h2 {
  color: #28666e;
}

.clickable-card:hover img,
.clickable-card:focus img {
  transform: scale(1.1);
}

.article-body {
  padding: 24px;
}

.clickable-card h2 {
  margin: 0 0 18px 0;
  font-family: "Bebas Neue", cursive;
  font-size: 1.9rem;
  letter-spacing: 0.06em;
  transition: color 0.3s ease-out;
}

article {
  --link-color: black;
  --link-icon-translate: -20px;
  --link-icon-opacity: 0;
  position: relative;
  max-width: 400px;
  height: 500px;
  margin-inline: auto;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: none;
  background: #fff;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
}

article img {
  max-width: 100%;
}

figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

/************************ 
Generic layout (demo looks)
**************************/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* CSS Media Queries untuk Tampilan Mobile */
@media (max-width: 768px) {
  .article-body {
    padding: 10px;
  }

  p {
    font-size: 18px;
    margin-top: 20px;
  }

  .article-body div:last-child {
    border: none;
  }

  .row {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
  }

  .col-sm-6 {
    flex-basis: 48%;
    max-width: 48%;
    box-sizing: border-box;
  }

  .col-sm-6+.col-sm-6 {
    margin-left: 10px;
  }

  .mobile-text-size {
    font-size: 14px !important;
    /* Ukuran teks untuk tampilan mobile, sesuaikan sesuai kebutuhan */
  }

  .nav-pills-custom {
    overflow-x: auto;
  }
  .nav-link {
    white-space: nowrap;
    margin-right: 10px; /* Jarak antar tabs */
  }
}

.dashboard {
  background-color: var(--white-color);
  padding: 10px;
  border-radius: 10px;
}

.box {
  position: relative;
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  padding: 2rem;
  border-radius: 0.75rem;
  border: 1px;
  background-color: var(--background-color-gray);
  color: var(--secondary-color-gray);
  font-size: 12px;
  margin: 12px;
}

.box .heading {
  text-align: justify;
  color: var(--p-color-black);
  letter-spacing: -0.03em;
  font-size: 20px;
  font-weight: var(--font-weight-medium);
  line-height: 1.5;
  margin-bottom: 8px;
  margin-top: 0;
}

.box .heading-sm {
  font-size: 14px;
  text-shadow: 0 0 80px #17181d;
}

.stats {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stat-item {
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  height: 50px;
}

.stats .stat-item p {
  font-weight: var(--font-weight-medium);
  font-size: var(--p-font-size);
  font-style: var(--body-font-family);
  color: var(--p-color-black);
}

.stat-item:hover {
  background-color: lightgray;
  border-radius: 10px;
}

.stat-item.clicked {
  background-color: lightblue;
  border-radius: 10px;
}

.stat-icon {
  color: var(--primary-gray);
}

.profile-tittle {
  text-align: center;
  width: 100%;
  box-shadow: -6px 8px 10px -6px inset var(--primary-blue);
  border-radius: var(--border-radius-small);
}

.white-point {
  display: inline-flex;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: var(--black-color);
}

.main {
  padding: 1em 0;
}

.main img {
  max-width: 100%;
  height: auto;
}

/*====== cards style ==========*/

.cards-grid-ppid {
  display: grid;
  width: auto;
}

.card-ppid {
  width: auto;
  height: 450px;
  border-radius: 6px;
  box-shadow: 0 20px 40px 0 rgba(173, 181, 189, 0.1);
  border: solid 1px rgba(129, 147, 174, 0.12);
  background-color: #fff;
  padding: 2.5em;
  text-align: center;
  position: relative;
}

.card-yellow::before {
  content: "";
  position: absolute;
  background-color: var(--primary-yellow);
  top: -8px;
  left: -1px;
  width: calc(100% + 2px);
  height: 15px;
  border-radius: 6px 6px 0 0;
}

.card-blue::before {
  content: "";
  position: absolute;
  background-color: var(--primary-blue);
  top: -8px;
  left: -1px;
  width: calc(100% + 2px);
  height: 15px;
  border-radius: 6px 6px 0 0;
}

.card-green::before {
  content: "";
  position: absolute;
  background-color: var(--primary-green);
  top: -8px;
  left: -1px;
  width: calc(100% + 2px);
  height: 15px;
  border-radius: 6px 6px 0 0;
}

.card-body-ppid {
  padding-top: 1em;
}

.card-head-ppid {
  font-weight: var(--font-weight-bold);
  font-size: var(--h5-font-size);
  margin-bottom: 1em;
  color: var(--black-color);
  text-transform: capitalize;
}

.card-content-ppid {
  font-weight: var(--font-weight-medium);
  font-size: var(--menu-font-size);
  margin-bottom: 1em;
  color: var(--black-color);
  text-transform: capitalize;
}

.card-ppid .btn-custom-home {
  color: var(--border-color);
}

.card-ppid .btn-custom-home:hover {
  background-color: var(--primary-custom);
  border: 1px solid var(--primary-custom);
  color: #fff;
  box-shadow: none;
}

.card-ppid .btn-custom-home:hover:before {
  transform: scaleX(1);
}

.horizontal-card {
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

.card-image-logo {
  flex: 1;
  max-width: 150px;
  justify-content: center;
  text-align: center;
}

.card-text-logo {
  flex: 2;
}

.card-text-logo p {
  font-size: (var(--p-font-size-second));
  color: var(--black-color);
  text-align: justify;
}

.card-text-logo h3 {
  flex: 2;
}

.follow-scroll {
  position: sticky;
  top: 80px;
  align-self: flex-start;
}

.lyrics-container {
  border-radius: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  overflow: auto;

  font-size: 16px;
  line-height: 1.5;
  color: #333;
  height: 450px;
}

.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 767px) {
  .lyrics-container {
    overflow: auto;
    margin-top: 10px;
    height: auto;
    /* Hapus tinggi tetap */
    max-height: 300px;
    /* Tetapkan tinggi maksimum */
    padding: 10px;
    /* Kurangi padding untuk tampilan yang lebih kompak */
    font-size: 14px;
    /* Kurangi ukuran font untuk tampilan yang lebih kecil */
  }

  .col-12 {
    flex: 0 0 100%;
    /* Setiap kolom akan menempati lebar penuh */
    max-width: 100%;
    /* Lebar maksimum setiap kolom adalah 100% */
  }

  .custom-list p {
    font-size: 10px;
    width: auto;
  }

  .custom-list li {
    margin-left: -30px;
  }

  .custom-list-bottom li {
    margin-top: -20px;
  }

  .custom-list-bottom P {
    max-width: 100px;
  }

  .mobile-text {
    word-wrap: break-word;
    /* Memecah kata jika melebihi lebar kontainer */
    white-space: normal;
    /* Mengatur spasi normal agar teks tidak melebar ke kanan */
  }
}

.highlight-terdidik {
  color: var(--primary-blue);
  font-weight: var(--font-weight-bold);
}

.highlight-terbaik {
  color: var(--primary-green);
  font-weight: var(--font-weight-bold);
}

@keyframes lyricAnimation {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.details-img--ball {
  height: 300px;
  width: auto;
  margin: 0 auto;
}

.card-pdf-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btn-pdf {
  background-color: #007bff;
  color: white !important;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin: 5px;
}

.btn-pdf-list {
  background-color: #007bff;
  width: 20%;
  color: white !important;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

.btn-pdf:hover {
  background-color: #0056b3;
  color: white;
}

.sample {
  border-radius: 10px;
  overflow: hidden;
}

.sample-title {
  line-height: 2;
  font-size: 1rem;
  background: var(--secondary-color-blue);
  color: #fff;
  padding: 0 20px;
  letter-spacing: 1px;
}

.sample-code {
  border-left: 2px dashed #e4f0d0;
  border-right: 2px dashed #e4f0d0;
  padding: 20px;
  line-height: 1.5;
  font-weight: 900;
}

.sample-html {
  padding: 20px;
  background: #f0f3f4;
  font-weight: 900;
  text-align: justify;
}

.code,
.sample-title {
  font-family: "Fira Mono", monospace;
}

.code-details {
  color: #783f8e;
}

.code-summary {
  color: #e36588;
}

.content-section {
  margin-top: 40px;
  /* Sesuaikan nilai ini sesuai kebutuhan Anda */
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

/* CSS untuk padding top pada konten */
.tab-content {
  margin-bottom: 20px;
}

/* CSS untuk tampilan mobile */
@media (max-width: 767px) {
  .tab-content {
    padding-top: 30px;
    /* Sesuaikan nilai ini untuk memastikan konten tidak terpotong */
  }
}

.pagination-publik {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;

}

.pagination-publik .pagination-sub-publik {
  display: flex;
  padding: 0;
}

.pagination-publik .break-publik {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
}


/* Previous and Next buttons */
.previous-publik,
.next-publik {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin: 0 0.25rem;
  cursor: pointer;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  transition: background-color 0.3s, color 0.3s;
}

.previous-publik:hover,
.next-publik:hover {
  background-color: #e9ecef;
  color: #0056b3;
}
.previous-publik:active,
.next-publik:active {
  background-color: #0056b3;
  color: #fff;
}

/* Individual page buttons */
.page-publik {
  margin: 0 0.25rem;
}

.page-publik a {
  display: block;
  padding: 0.5rem 0.75rem;
  color: #007bff;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}

.page-publik a:hover {
  background-color: #e9ecef;
  color: #0056b3;
}

/* Active page */
.page-publik {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #000; /* Teks hitam */
  background-color: #fff; /* Latar belakang putih */
}


.active-publik a {
  color: #ffff !important; /* Teks putih saat aktif */
  background-color: #007bff; /* Latar belakang biru saat aktif */
  cursor: default;
  font-weight: bold;
}

/* Tambahkan ini ke file CSS Anda */
@media (max-width: 767px) {
  .card-news-home {
    height: 850px;
    overflow: auto;
  }

  .tabs-menu-custom {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .tabInfo {
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }

  .tabs-content-custom {
    margin-top: 20px;
  }
}

.btn-primary-layanan {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.btn-secondary-layanan {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

@media (max-width: 768px) {
  .container-layanan {
    max-width: 100%;
    padding: 0.5rem;
  }

  .small-img-layanan {
    width: 40px;
    height: 30px;
    margin-right: 10px;
  }

  .email-section .icon,
  .social-media-section .icon {
    width: 20px;
    height: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .link-section .link-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .link-section .icon {
    width: 24px;
    height: 24px;
    margin-top: 0.5rem;
  }

  .link-section .link-content {
    margin-bottom: 0.5rem;
  }

  .link-section .link-content p {
    font-size: 13px !important;
    padding: 5px;
    font-weight: 900;
  }

  .step-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .step-number {
    margin-right: 0;
  }

  .step-content {
    width: 100%;
  }

  .follow-scroll {
    position: static !important;
  }

  .container-berita {
    display: flex;
    justify-content: center;
    /* Memusatkan kartu */
  }

  .grid-berita {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .projects__title {
    font-size: var(--h4-font-size);
    margin-bottom: 0.5rem;
    text-align: left;
    /* Mengatur teks ke kiri */
  }

  .projects__description {
    font-size: var(--small-font-size);
    margin-bottom: 0.5rem;
    text-align: left;
    /* Mengatur teks ke kiri */
  }



  .struktur-container {
    padding: 10px;
  }

  .highcharts-figure {
    width: 100%;
    height: auto;
  }

  #container h4 {
    font-size: 2.3vw;
    line-height: 3vw;
  }

  #container p {
    font-size: 2.3vw;
    line-height: 3vw;
  }
}

.email-section,
.link-section,
.social-media-section {
  margin-bottom: 1rem;
}

.email-section {
  display: flex;
  align-items: center;
}

.email-section .icon {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}

.email-section a {
  color: #000;
  text-decoration: none;
}

.link-section .link-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border: 2px solid var(--secondary-color-blue);
  border-radius: 8px;
  margin-bottom: 0.5rem;
  text-decoration: none;
  color: #000;
}

.link-section .link-card:hover {
  background-color: var(--secondary-color-blue);
  color: var(--white-color);
}

.link-section .link-content h5 {
  font-size: var(--p-font-size);
  padding: 5px;
  font-weight: 900;
}

.link-section .link-content h6 {
  font-size: var(--p-font-size-second);
  padding: 5px;
  font-weight: 900;
}

.link-section .link-content p {
  font-size: var(--p-font-size);
  padding: 5px;
  font-weight: 900;
}

.link-section .icon-layanan {
  width: 32px;
  height: 32px;
}

.step-container {
  display: flex;
  align-items: flex-start;
  padding: 15px;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.step-number {
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.step-content {
  flex: 1;
}

.step-content h4 {
  margin-top: 0;
}

.step-content ul {
  list-style-type: disc;
  padding-left: 20px;
  font-weight: 900;
}

.step-content ul ul {
  list-style-type: circle;
}

.step-content ul ul li {
  list-style-type: circle;
  font-weight: 300;
  padding: 3px;
}

.step-content p {
  margin: 0;
  font-weight: 900;
}

.closed {
  color: red;
  font-weight: bold;
  margin-left: 10px;
}

.projects {
  transition: background-color 0.4s;
}
.projects__container {
  row-gap: 2rem;
}
.projects__card {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 2rem;
  border-radius: 0.75rem;
  transition: background-color 0.4s;
  max-width: 500px;
  width: 100%;
  text-align: left;
}

.projects__card:hover {
  background-color: var(--section-bg-color-blue);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
}

.projects__image {
  position: relative;
  margin-bottom: 0.75rem;
}

.projects__img {
  width: 100%;
  height: 200px; /* Atur tinggi sesuai kebutuhan */
  object-fit: cover;
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;
}

.projects__buttons {
  display: flex;
  column-gap: 1rem;
  text-align: left;
}

.projects__link {
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  color: var(--text-color);
  font-size: var(--small-font-size);
  font-weight: var(--font-medium);
  transition: color 0.4s;
}

.projects__link i {
  font-size: 1rem;
  color: var(--title-color);
  transition: color 0.4s;
}

.projects__link:hover,
.projects__link:hover i {
  color: var(--border-color);
}


.projects__button {
  position: absolute;
  right: 1rem;
  bottom: -1.5rem;
  padding: 1rem;
  text-align: left;
}

.projects__content {
  margin-bottom: 1.25rem;
  min-height: 100px; /* Atur tinggi sesuai kebutuhan */
}

.projects__subtitle {
  position: relative;
  display: inline-block;
  font-size: var(--small-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--text-color);
  margin-bottom: 0.75rem;
  padding-left: 1.75rem;
}

.projects__subtitle::after {
  content: "";
  width: 20px;
  height: 1px;
  background-color: var(--text-color);
  position: absolute;
  left: 0;
  top: 50%;
}


.button-berita {
  background-color: var(--border-color);
  padding: 0.8rem 1.2rem;
  color: var(--white-color);
  font-weight: var(--font-medium);
  border-radius: 0.75rem;
  transition: background-color 0.4s;
}

.button-berita i {
  font-size: 1.25rem;
}

.button-berita:hover {
  background-color: var(--black-color);
  color: var(--background-color-gray);
}


.section-berita {
  padding-block: 1rem 2rem;
}


.grid-berita {
  display: grid;
  grid-template-columns: repeat(auto-fit,
      minmax(300px, 1fr));
  /* Mengatur grid responsif */
  gap: 1.5rem;
}



.highcharts-figure,
.highcharts-data-table table {
  min-width: 100%;
  max-width: 800px;
  margin: 1em auto;
  height: 1500px;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

#container-org {
  height: 100%;
  width: 100%;
  /* Sesuaikan tinggi untuk tampilan mobile */
}

#container-org h4 {
  text-transform: none;
  font-size: 11px;
  font-weight: var(--font-bold);
  color: #000;
}

#container-org p {
  font-size: 10px;
  line-height: 16px;
  color: var(--p-color-white);
}

@media screen and (max-width: 600px) {
  .struktur-container {
    padding: 5px;
  }

  #container-org {
    height: 100%;
    /* Sesuaikan tinggi untuk tampilan mobile */
  }

  #container-org h4 {
    font-size: 5px;
    line-height: 3vw;
  }

  #container-org p {
    font-size: 5px;
    line-height: 3vw;
  }

}

.struktur-container {
  height: 1900px;
  /* Mengatur tinggi kontainer untuk memenuhi tampilan penuh */
}

.org-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.org-chart__node {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  text-align: center;
  width: 150px;
}

.org-chart__node--root {
  font-size: 1.5em;
}

.org-chart__children {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.custom-prev,
.custom-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1e88e5;
  /* Background semi transparan */
  color: #fff;
  padding: 0;
  cursor: pointer;
  z-index: 10;
  border-radius: 20%;
  font-size: 32px;
  /* Perbesar ukuran panah */
  width: 50px;
  /* Perbesar ukuran tombol */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-prev::before {
  content: "\2190";
  /* Panah kiri */
  font-size: 32px;
  /* Perbesar ukuran panah */
}

.custom-next::before {
  content: "\2192";
  /* Panah kanan */
  font-size: 32px;
  /* Perbesar ukuran panah */
}

.custom-prev {
  left: 10px;
  /* Posisikan di kiri */
}

.custom-next {
  right: 10px;
  /* Posisikan di kanan */
}

.custom-prev:hover,
.custom-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
  /* Warna saat hover */
}

.tab-aduan {
  display: flex;
  gap: 10px; /* Jarak antar tombol */
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 10px;
}

.tab-aduan button {
  flex: 1;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  border: 2px solid #007bff; /* Warna border biru */
  background-color: white; /* Warna latar belakang putih */
  color: black; /* Warna teks default */
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Warna saat tombol aktif */
.tab-aduan button.active {
  background-color: #007bff; /* Warna latar belakang biru */
  color: white; /* Warna teks putih */
  border-color: #007bff; /* Warna border biru */
}

.tab-aduan .slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  /* Setengah dari lebar container */
  background-color: var(--custom-btn-bg-hover-color);
  /* Warna slider */
  border-radius: 20px;
  transition: transform 0.3s ease;
  /* Efek geser slider */
  z-index: 1;
  /* Pastikan slider di bawah teks */
}

.card.card-aduan {
  border-radius: 20px;
  box-shadow: none;
  background-color: #f5f5f5;
}

.content-aduan {
  /* position: absolute; */
}

.aduan-maskot {
  position: absolute;
  bottom: -80px;
  right: -120px;
  z-index: 1;
}

@media (max-width: 640px) {
  .aduan-maskot {
    display: none;
  }
}
.carousel-ijazah img {
  width: 50%;
  height: 50%;
  border-radius: 10px; 
}
.img-container-ijazah {
  height: 100%;
  width: 100%;
}
.a-box-ijazah {
  width: 100%;
  height: 100%;
  text-align: center;
}
.carousel-ijazah {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  position: relative;
}

.carousel-ijazah img {
  max-width: 100%;
  max-height: 100%;
}

.prev-carousel-ijazah,
.next-carousel-ijazah {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #00acee;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  width: 50px;
}

.prev-carousel-ijazah {
  left: 150px;
}

.next-carousel-ijazah {
  right: 150px;
}

.prev-carousel-ijazah:hover,
.next-carousel-ijazah:hover {
  background-color: #555;
}

.prev-carousel-ijazah:active,
.next-carousel-ijazah:active {
  background-color: #777;
}
.fade-in-ijazah {
  opacity: 0; 
  transform: translateY(20px); 
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in-ijazah.active {
  opacity: 1; 
  transform: translateY(0); 
}

.modal-ijazah {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-ijazah {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 40%;
  max-height: 90%;
  overflow: auto;
}

.modal-content-ijazah img {
  width: 100%;
  height: auto;
}

.close-button-ijazah {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .carousel-ijazah img {
    width: 100%;
    height: auto;
  }

  .prev-carousel-ijazah,
  .next-carousel-ijazah {
    width: 40px; 
    font-size: 16px; 
    padding: 3px 8px; 
  }

  .prev-carousel-ijazah {
    left: 5px; 
  }

  .next-carousel-ijazah {
    right: 5px;
  }

  .tab-aduan button {
    font-size: 10px;
  }
  .modal-content-ijazah {
    max-width: 100%;
    max-height: 100%;
  }
  
  .modal-content-ijazah img {
    width: 100%;
    height: auto;
  }
}


@media (max-width: 480px) {
  .carousel-ijazah img {
    width: 100%;
    height: auto; 
  }

  .prev-carousel-ijazah,
  .next-carousel-ijazah {
    width: 40px; 
    font-size: 18px; 
    padding: 2px 6px; 
  }

  .prev-carousel-ijazah {
    left: 2px;
  }

  .next-carousel-ijazah {
    right: 2px;
  }
  .tab-aduan button {
    font-size: 10px;
  }
  .modal-content-ijazah {
    max-width: 100%;
    max-height: 100%;
  }
  
  .modal-content-ijazah img {
    width: 100%;
    height: auto;
  }
}

.image-profile {
  display: flex;
  justify-content: center; /* Posisi horizontal tengah */
  align-items: center; /* Posisi vertikal tengah */
  background-color: #f0f0f0; /* Warna latar belakang */
 
  border-radius: 10px; /* Sudut melengkung */
  margin-bottom: 20px; /* Jarak antara gambar dan konten di bawahnya */
}

.image-profile img {
  max-width: 600px; /* Lebar maksimal gambar */
  max-height: 400px; /* Gambar tidak melebihi lebar container */
  height: auto; /* Menjaga aspek rasio gambar */
  border-radius: 10px; /* Sudut melengkung untuk gambar */
}
.pagination-custom .page-item .page-link {
  color: var(--primary-blue) ;
  border: 1px solid var(--primary-blue);
  margin: 0 2px;
  border-radius: 4px;
}

.pagination-custom .page-item.active .page-link {
  background-color: var(--primary-blue) !important;
  color: white;
  border-color: var(--primary-blue) !important;
  box-shadow: none !important;
}

.pagination-custom .page-item.disabled .page-link {
  color: #6c757d;
  border-color: #dee2e6;
}

.pagination-custom .page-item .page-link:hover {
  background-color: var(--primary-blue);
  color: white;
}
/* LayananPublik.css */
.table-custom {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
}

.table-custom thead tr {
  background-color: var(--primary-blue);
  color: #ffffff;
  text-align: left;
}

.table-custom th,
.table-custom td {
  padding: 12px 15px;
}

.table-custom tbody tr {
  border-bottom: 1px solid #dddddd;
}

.table-custom tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.table-custom tbody tr:last-of-type {
  border-bottom: 2px solid var(--primary-blue);
}

.table-custom tbody tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.btn-primary-custom {
  background-color: var(--primary-blue);
  border: none;
  color: white;
  padding: 8px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary-custom:hover {
  background-color: var(--primary-blue);
}

.slick-slide {
  padding: 0 15px;
}

.slick-prev:before,
.slick-next:before {
  color: #2196F3;
  width: 40px; /* Set the width of the arrows */
  height: 40px; /* Set the height of the arrows */
  font-size: 34px;
}

.slick-prev {
  left: -35px;
}

.slick-next {
  right: -25px;
}

.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #2196F3;
}

.slick-dots li.slick-active button:before {
  color: #2196F3;
}


/* Carousel Styles */
.carousel-custom {
  height: 780px;
  overflow: hidden;
}

.carousel-custom .carousel-item {
  height: 780px;
}






/* Card Styles */
.post-slide {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.post-slide:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.post-img img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.post-content {
  padding: 20px;
}

.post-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.post-description {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 15px;
}

.post-date {
  font-size: 0.8rem;
  color: #999;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .banner-title {
    font-size: 2.5rem;
  }

  .banner-subtitle {
    font-size: 1.25rem;
  }

  .post-slide {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .banner-title {
    font-size: 2rem;
  }

  .banner-subtitle {
    font-size: 1rem;
  }

  .post-slide {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .banner-title {
    font-size: 1.5rem;
  }

  .banner-subtitle {
    font-size: 0.9rem;
  }

  .post-slide {
    margin-bottom: 20px;
  }
}