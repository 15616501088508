.background-ppdb {
    /* background-image: url("../ppdb-banner.jpg"); */
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    top: -200px;
    left: 0;

    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
}

.container-ppdb {
    max-width: 900px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 28px;
    margin: 0 auto;
}

.container-ppdb-v2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 10px;
    margin: 0 auto;
}

.card-content-ppdb {
    /* border: 1px solid #f0f0f0; */
    /* margin-inline: 200px; */
    border: none;
    box-shadow: none;
    border-radius: 15px;
    box-shadow: 0px 0px 30px rgba(82, 63, 105, 0.05);
    min-height: 250px;
}

.card-content-ppdb .card-content-title {
    font-weight: 700;
    letter-spacing: 0.5px;
}

.card-title {
    color: #fff;
    padding: 20px 0px 20px 0px;
    border-bottom: 3px solid #2f9e51;
    letter-spacing: 0.5px;
}

.card-title span {
    background-color: #2f9e51;
    padding: 20px;
}

.button-ppdb {
    border-radius: 10px;
    text-transform: inherit;
    font-size: 17px;
    background-color: #2f9e51 !important;
    transition: transform .2s;
    box-shadow: none;
    font-weight: 700;
    letter-spacing: 0.7px;
}

.button-ppdb:hover {
    box-shadow: none;
    transition: 0.3s ease;
    transform: scale(1.1);
}

.button-ppdb:focus {
    background-color: #2f9e51 !important;
    box-shadow: none;
}

.button-ppdb:active {
    background: #449DD5 !important;
    box-shadow: none !important;
}

a.link-ppdb {
    text-decoration: underline;
    color: #449DD5;
    letter-spacing: 0.5px;
}

a.link-ppdb:hover {
    color: #2f9e51;
    /* transition: 0.5s; */
    transition: 0.3s ease;
    transform: scale(1.2);
}

.ppdb-title {
    font-weight: 400;
    /* border-bottom-width: 100px; */
    padding-bottom: 10px;
    /* color: #449DD5; */
    color: #000;
    letter-spacing: 0.5px;
}

ul.persyaratan > li {
    margin-bottom: 10px;
}

.border-title {
    border: 3px solid #F8BF16;
}

.subtitle {
    font-weight: 500;
    letter-spacing: 0.5px;
}

.card-ppdb {
    cursor: pointer;
    position: relative;
    height: 0;
    padding-bottom: 120%;
    --offset-multiplier: 4px;
    transition: transform 0.6s ease;
    --translate: 0;
    transform: translate(var(--translate), var(--translate));
  }

  .card-ppdb:hover {
    --offset-multiplier: 6px;
  }

  .card-ppdb:hover {
    --translate: calc(-1px * (var(--cards) - 1));
    transition: transform 0.3s ease;
  }

  .child {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px -4px 8px rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    transition: inherit;
    --translate: calc(var(--offset) * var(--offset-multiplier));
    transform: translate(var(--translate), var(--translate));
    z-index: 5;
  }

  .content-title {
    font-weight: normal;
    color: #000;
    line-height: 1.25;
  }

  .child:nth-child(1) {
    --offset: 0;
    z-index: 4;
  }
  .child:nth-child(2) {
    --offset: 1;
    z-index: 3;
  }
  .child:nth-child(3) {
    --offset: 2;
    z-index: 2;
  }
  .child:nth-child(4) {
    --offset: 3;
    z-index: 1;
  }
  .child:nth-child(5) {
    --offset: 4;
    z-index: 0;
  }

/* .card-ppdb {
    border: none;
    box-shadow: none;
    border-top: 5px solid black;
    border-radius: 15px;
    border-top-color: #449DD5;
    cursor: pointer;
    box-shadow: 0px 0px 30px rgba(82, 63, 105, 0.05);
    position: relative;
    min-height: 250px;
    transition: transform .2s;
} */

.card-ppdb .card-body {
    padding: 30px !important;
}

.text-date {
    color: #7e7e7e
}

/* .card-ppdb:hover {
    transition: 0.3s ease;
    border: none;
    border-top: 5px solid #2f9e51;
    transform: scale(1.1);
} */

/* .card-ppdb .card-footer {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
} */