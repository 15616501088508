:root {
    --primary-custom: #1E88E5;
    --success-custom: #16A75C;
    --primary-dark-custom: #0D47A1;
    --primary-light-custom: #e5f8ff;
    --info-light-custom: #BBDEFB;
    --background-default: #F5F5F5;
    --badge-primary-custom: #C3E9D0;
}

.agenda-week {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat-Regular" !important;
}

.agenda-week li.active {
    background-color: var(--primary-custom);
}

.agenda-week li.active .hari {
    color: #fff;
}

.agenda-week li.active .tanggal {
    color: #fff;
}

.agenda-week li.active:hover {
    background-color: #1976D2;
}

.agenda-week li.active:hover .hari {
    color: #fff;
}

.agenda-week li {
    border-radius: 5px;
    min-width: calc(25% - 15px);
    max-width: calc(25% - 15px);
    cursor: pointer;
    transition: 0.2s ease;
    margin-inline: 3px;
}

.agenda-week li:hover {
    background-color: var(--info-light-custom);
}

.agenda-week li:hover .hari {
    color: #424242;
}

.agenda-week li .hari {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-top: 5px;
    color: #868C89;
    transition: 0.2s ease;
}

.agenda-week li .tanggal {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #424242;
}

.timeline-custom {
    height: 400px;
}

.timeline-custom ul {
    height: 400px;
    padding: 15px;
    overflow: hidden;
    overflow-y: scroll;
}

.timeline-dashboard {
    list-style: none;
    margin-top: 30px;
}


.timeline-content-dashboard {
    /* border-left: 3px solid black; */
    /* border-left: 3px #e0e0e0 solid; */
    /* padding-left: 30px; */
    /* float: left; */
    width: 100%;
    font-family: "Montserrat-Regular" !important;
}

.timeline-content-dashboard .timeline-title {
    font-size: 12px;
    font-weight: bold;
    color: var(--primary-custom);
    margin-bottom: 10px;
}

.timeline-dashboard li {
    /* margin-top: 10px; */
    padding-bottom: 1.5rem;
    border-left: 3px #e0e0e0 solid;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;

    font-family: "Montserrat-Regular" !important;

    &:last-child {
        border: 0px;
        padding-bottom: 0;
    }

    &:before {
        content: "";
        position: absolute;
        border-radius: 50%;
        background: var(--primary-custom);
        height: 15px;
        width: 15px;
        left: -8.5px;
        top: 0px;
    }
}

.timeline-dashboard .li-started {
    /* margin-top: 10px; */
    padding-bottom: 1.5rem;
    border-left: 3px #e0e0e0 solid;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;

    &:last-child {
        border: 0px;
        padding-bottom: 0;
    }

    &:before {
        content: "";
        position: absolute;
        border-radius: 50%;
        background: var(--primary-custom);
        height: 15px;
        width: 15px;
        left: -8px;
        animation: pulse 2s infinite;
        top: 0px;
    }
}

.pulse-dashboard {
    position: absolute;
    border-radius: 50%;
    background: var(--primary-custom);
    height: 15px;
    width: 15px;
    left: 41px;
    /* left: 41px;
    top: 220px; */
}

.pulse-dashboard-started {
    position: absolute;
    border-radius: 50%;
    background: var(--primary-custom);
    height: 15px;
    width: 15px;
    animation: pulse 2s infinite;
    left: 41px;
    /* left: 41px;
    top: 220px; */
}

.card-agenda-not-started-yet {
    /* border: 1px solid $bg-default; */
    border-radius: 10px;
    background-color: var(--background-default);
    color: var(--primary-custom);
    padding: 20px;
    cursor: pointer;
    font-family: "Montserrat-Regular" !important;
}

.card-agenda-not-started-yet .title {
    color: #333;
    font-size: 16px;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
}

.card-agenda-not-started-yet .footer {
    color: #333;
    margin-top: 20px;
    font-size: 13px;
}

.card-agenda {
    border: 1px solid var(--primary-custom);
    border-radius: 10px;
    background-color: #fff;
    color: var(--primary-custom);
    padding: 20px;
    cursor: pointer;
    font-family: "Montserrat-Regular" !important;
}

.card-agenda .title {
    color: var(--primary-custom);
    font-size: 16px;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
}

.card-agenda .footer {
    margin-top: 20px;
    font-size: 13px;
}

.card-agenda:hover {
    background-color: var(--primary-light-custom);
    transition: 0.3s ease;
}

.card-agenda-finish {
    border: 1px solid var(--primary-custom);
    border-radius: 10px;
    background-color: var(--primary-light-custom);
    color: var(--primary-custom);
    padding: 20px;
    cursor: pointer;
    font-family: "Montserrat-Regular" !important;
}

.card-agenda-finish .title {
    color: var(--primary-custom);
    font-size: 16px;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
}

.card-agenda-finish .footer {
    margin-top: 20px;
    font-size: 13px;
}

.card-agenda-detail {
    padding: 20px;
    border: none !important;
    border-radius: 10px;
    background-color: #fafafa;
    font-family: "Montserrat-Regular" !important;
    /* height: 95%; */
}

.card-agenda-detail .title {
    color: var(--primary-custom);
    font-size: 16px;
    font-weight: bold;
}

.card-agenda-detail .title-content {
    color: #9e9e9e;
    font-size: 14px;
    font-weight: bold;
}

.link-detail a {
    margin-top: 20px;
    padding-inline: 10px;
    border-radius: 10px;
    transition: 0.2s ease;
}

.link-detail a .icon {
    color: var(--primary-custom);
}

.link-detail a:hover {
    background-color: var(--primary-custom);
    border-radius: 10px;
    color: #fff;
    padding-inline: 10px;
}

.link-detail a:hover .icon {
    color: #fff;
    transition: 0.2s ease;
}

.badge.bg-primary-custom {
    background-color: #BBDEFB !important;
    color: var(--primary-custom) !important;
    box-shadow: none;
    border-radius: 10px;
}

.badge.bg-default {
    background-color: #eee !important;
    color: #9e9e9e !important;
    box-shadow: none;
    border-radius: 10px;
}

.text-main {
    color: #333;
    font-family: 'Montserrat-SemiBold';
}

.text-secondary-custom {
    color: #9e9e9e;
}

a.text-link {
    color: #007bb5 !important;
    font-family: 'Montserrat-SemiBold';
}

.modal-header {
    font-family: "Montserrat-Regular" !important;
}


@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 var(--primary-custom);
    }

    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 var(--primary-custom);
        box-shadow: 0 0 0 0 var(--primary-custom);
    }

    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}