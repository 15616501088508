:root {
  --primary-custom: #1e88e5;
  --success-custom: #16a75c;
  --text-primary: #0d47a1;
  --text-success: #006430;
  --text-warning: #ffd026;
}

body {
  background: #fafafa;
}

.banner-container-home {
  height: 780px;
}
.banner-container-custom {
  width: 100%;
  height: 100%;
}

.carousel-custom {
  width: 100%;
  height: 100%;
}

.banner-home {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
  /* position: absolute; */
  /* top: 0; */
}

.banner-home-overlay {
  /* background: rgba(0, 100, 48, 0.6); */
  background: radial-gradient(
    100% 820.78% at 0% 0%,
    rgba(0, 8, 86, 0.4) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  /* background: radial-gradient(100% 820.78% at 0% 0%, rgba(0, 32, 0, 0.7) 0%, rgba(0, 8, 86, 0.5) 61.62%); */
  /* background: radial-gradient(100% 820.78% at 0% 0%, rgba(0, 8, 86, 0.7) 0%, rgba(0, 32, 0, 0.7) 61.62%); */
  /* Adjust the opacity by changing the last value (0.5) */
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
}

.banner-content {
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 3;

  .banner-title {
    color: #fff;
    /* font-weight: 900; */
    font-family: "Montserrat-ExtraBold" !important;
    font-size: 36px;
    text-transform: uppercase;
    text-align: center;
    line-height: 3rem;
  }

  .banner-subtitle {
    margin-top: 10px;
    color: #fff;
    font-weight: bold;
    font-weight: 200;
    font-family: "Montserrat-Bold";
    text-transform: uppercase;
    text-align: center;
    line-height: 3rem;
    font-size: 24px;

    .span-current-text {
      border-right: 0.05em solid #fff;
      animation: caret 1s steps(1) infinite;
    }
  }

  .banner-search-content {
    margin-top: 50px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .input-group .btn-search-home {
      padding-top: 0;
    }

    .banner-search-input {
      margin-top: 30px;
      /* display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap; */
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 36rem;
      width: 100%;

      .search {
        height: 60px;
        width: 95%;
        max-width: 400px;
        border-radius: 18px;
        display: flex;
        position: relative;
      }

      .btn-search-home-custom {
        background-color: #fff;
        color: var(--primary-custom);
        font-weight: bold;
        border: none !important;
        border-radius: 10px;
        padding: 0;
        padding-inline: 20px;
        height: 3rem;
        /* background-color: var(--primary-custom);
        padding: 14px;
        height: 100%;
        font-weight: bold; */
        /* border-radius: 10px; */
        /* margin: 0;
        border: none !important;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px; */
        /* border-radius: 20px; */
      }

      .btn-search-home-custom .icon {
        margin-right: 5px;
        color: var(--primary-custom);
        transition: 0.2s ease;
      }

      .btn-search-home-custom:hover {
        background-color: var(--primary-custom);
        color: #fff;
        box-shadow: none !important;
      }

      .btn-search-home-custom:hover .icon {
        color: #fff;
      }

      .btn-search-home-custom:focus {
        box-shadow: none !important;
      }

      .input-custom {
        /* border-radius: 10px !important; */
        padding: 0;
        padding-inline: 20px;
        width: 100%;
        height: 3rem;
        /* padding: 10px; */
        border: none !important;
        border-radius: 10px;
      }

      .input-custom:focus {
        border: none !important;
      }

      /* .btn-search-home-custom {
        outline: none;
        border: none;
        background: var(--primary-custom);
        color: #fff;
        width: 60px;
        height: 60px;
        border-radius: 18px;
        cursor: pointer;
        position: relative;
        transition: left ease 0.4s, background ease 0.2s, color ease 0.2s;
      } */

      /* Add styles for cancel button if needed */

      .search.open .btn-search-home-custom {
        border-radius: 18px 0 0 18px;
        left: 5px;
        color: var(--primary-custom);
        background: #fff;
        transition: left ease 0.4s, background ease 0.2s, color ease 0.2s;
      }

      .search.open .input-custom {
        width: 86%;
        /* Expand the width when open */
        padding: 0 14px;
        /* Add padding for input content */
        transition: all 0.4s ease;
        /* Add transition for the input when open */
      }

      .search.open .cancel {
        display: block;
        right: 14px;
        visibility: visible;
        transition: right ease 0.3s, visibility ease 0.2s, transform ease 0.2s,
          background 0.15s;
        transform: rotate(0deg);
      }
    }

    /* .banner-search-input input {
      border-radius: 10px;
      border: none !important;
    } */

    .btn-search-popular {
      border: none;
      background-color: var(--primary-custom);
      box-shadow: none;
      transition: 0.3s ease;
      border-radius: 10px;
      margin: 0;
    }

    .btn-search-popular:hover {
      scale: 1.1;
      color: #fff;
      z-index: 10;
    }

    .banner-search-popular {
      margin-top: 40px;
      font-weight: bold;
      color: #fff;
      border-radius: 10px;
    }

    .banner-search-most-popular {
      margin-top: 20px;
      /* overflow: auto; */

      .slider-content {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: auto;
        margin: 0 auto;
      }

      .btn-most-popular {
        box-shadow: none;
        background-color: #fff;
        color: #000;
        font-weight: bold;
        border: none;
        border-radius: 10px;
        transition: 0.2s ease;
        padding: 10px !important;
        width: 8rem;
      }

      .btn-most-popular:hover {
        background-color: var(--primary-custom);
        color: #fff;
        box-shadow: none;
      }

      .btn-most-popular:focus {
        background-color: var(--primary-custom);
        color: #fff;
      }

      .btn-most-popular .icon {
        transition: color 0.2s ease;
        color: var(--primary-custom);
        margin-left: 5px;
      }

      .btn-most-popular:hover .icon {
        color: #fff;
      }

      .btn-most-popular:focus .icon {
        color: #fff;
      }
    }
  }
}

@keyframes caret {
  0%,
  100% {
    border-right-color: transparent;
  }

  50% {
    border-right-color: #fff;
  }
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3f729b;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1c2331;
}

.btn.btn-sm.indigo:hover {
  color: #fff;
}

.nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
}

#categories > .col-md-4.mb-5 {
  margin-bottom: 8rem !important;
}

.sectionPage {
  position: cover;
  width: 100%;
  height: 100%;
  top: -60px;
  margin-bottom: -10px;
}

.sectionPage::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background: url("https://mdbootstrap.com/wp-content/uploads/2016/12/big-bundle1.jpg");
  background-position: center center;
  background-size: cover;
  filter: opacity(0.1);
}

.col-separator-home {
  background-color: #2d2e2f;
  padding: 25px;
  height: 250px;
}

.colVerticalLine {
  background-color: #2d2e2f;
}

.sub-title-separator {
  color: snow;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}

.vl-one {
  border-left: 3px solid rgb(23, 173, 179);
  margin-left: 2px;
  margin-right: 2px;
  height: 100%;
}

.vl-two {
  border-left: 3px solid rgb(189, 144, 22);
  margin-left: 2px;
  margin-right: 2px;
  height: 100%;
}

.vl-three {
  border-left: 3px solid rgb(179, 25, 84);
  margin-left: 2px;
  margin-right: 2px;
  height: 100%;
}

.font-banner {
  text-transform: uppercase;
  font-family: verdana;
  line-height: 1;
  font-size: 3.5rem;
  min-width: 15em;
  font-weight: 700;
  color: #f5f5f5;
  text-shadow: 1px 1px 1px #65b0bb, 1px 2px 1px #65b0bb, 1px 3px 1px #65b0bb,
    1px 4px 1px #65b0bb, 1px 5px 1px #65b0bb, 1px 6px 1px #65b0bb,
    1px 7px 1px #65b0bb, 1px 8px 1px #65b0bb, 1px 9px 1px #65b0bb,
    1px 10px 1px #65b0bb, 1px 18px 6px rgba(16, 16, 16, 0.4),
    1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
    1px 30px 60px rgba(16, 16, 16, 0.4);
}

.font-banner-tikomdik {
  font-family: "Lato-Bold";
  line-height: 1;
  font-size: 3.5rem;
}

.bannerOne {
  width: 100%;
  z-index: 1;
}

.tabs-news {
  display: flex;
  position: relative;
  z-index: 1;
  padding-left: 10rem;
  width: 90%;
  top: -41px;
}

.navLink1 {
  color: #fff;
  width: 150px;
  font-weight: 900;
  text-align: center;
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
  background-color: #fec71c !important;
  border-color: #fec71c !important;
}

.navLink2 {
  color: #fff;
  width: 150px;
  font-weight: 900;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
  background-color: #70d3cc !important;
  border-color: #70d3cc !important;
}

.navLink2:hover {
  color: #009a77;
}

.navLink3 {
  color: #fff;
  width: 150px;
  font-weight: 900;
  text-align: center;
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
  background-color: #009a77 !important;
  border-color: #009a77 !important;
}

.navLink3:hover {
  color: #fec71c;
}

.navLink1.active {
  background-color: #fec71c !important;
  border-color: #fec71c !important;
}

.navLink2.active {
  background-color: #70d3cc !important;
  border-color: #70d3cc !important;
}

.navLink3.active {
  background-color: #009a77 !important;
  border-color: #009a77 !important;
}

.textCaption {
  font-size: 20px;
  margin-top: 1rem;
  font-weight: bold;
  text-indent: 3rem;
}

.textCaption02 {
  font-size: 15px;
  margin-top: 1rem;
  font-weight: bold;
  text-align: left;
  line-height: 0.8;
}

.textCaption03 {
  font-size: 20px;
  margin-top: 1rem;
  font-weight: bold;
  text-align: center;
}

.footer-cardHome-home {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: space-between !important;
}

.my-about-div {
  min-width: 15%;
  max-height: 60%;
  margin-top: 5%;
  background-color: var(--primary-custom);
  display: flex;
  position: relative;
  justify-content: center;
  border-radius: 10px 0px 0px 10px;
}

.my-about-div h3 {
  font-size: 1rem;
  /* color: var(--text-primary); */
  color: #fff;
  font-family: "Montserrat-Bold";
  text-transform: uppercase;
  letter-spacing: 3px;
  min-width: 250px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 35%;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
}

.about-box {
  display: flex;
  flex-direction: column;
  border-style: solid;
  border: none;
  border-top: 3px solid var(--text-warning);
  justify-content: space-between;
}

.title-upcoming {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 5px;
}

.title-upcoming .main-title {
  font-weight: 300;
  font-size: 4rem;
  letter-spacing: 10px;
}

.title-upcoming .sub-title {
  font-weight: bold;
  font-size: 4rem;
  background-color: #fec71c;
}

.rowTitle {
  position: absolute;
  z-index: 1;
}

.rowUpcomingEvents {
  display: flex;
  position: absolute;
  z-index: 2;
}

.col-upcoming-events {
  cursor: pointer;
  position: relative;
  height: 40vh;
  padding-left: 0px !important;
  background-size: cover;
}

.col-upcoming-events .overlay-col-upcoming-events {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  min-height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.8;
  letter-spacing: 3px;
}

.title-content-events {
  z-index: 3;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 2.5rem;
}

.each-slide > div {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  min-height: 70vh;
}

.each-slide .content {
  padding: 10vh;
  text-align: start;
  text-indent: 5%;
}

.customToltips {
  justify-self: center;
  align-self: center;
}

.indicator {
  cursor: pointer;
  padding: 5px;
  width: 130px;
  text-align: center;
  border: 1px #edf0f2 solid;
  background-color: #edf0f2;
  margin: 2px;
  border-radius: 8px;
  opacity: 0.5;
}

.indicator.active {
  border: 1px #edf0f2 solid;
  background-color: #edf0f2;
  opacity: 0.9;
}

.imgUE {
  position: relative;
  height: 100%;
  padding-left: 0px !important;
  background-size: cover;
}

.col-gallery {
  cursor: pointer;
  position: relative;
  height: 25vh;
  padding-left: 0px !important;
  background-size: cover;
}

.col-gallery .overlay-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  min-height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.8;
  letter-spacing: 3px;
}

.my-gallery-div {
  min-width: 15%;
  max-height: 50%;
  margin-top: 5%;
  background-color: #009a77;
  display: flex;
  position: relative;
  justify-content: center;
  border-radius: 0px 10px 10px 0px;
}

.my-gallery-div h3 {
  font-size: 1rem;
  color: white;
  text-transform: uppercase;
  letter-spacing: 3px;
  min-width: 250px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 35%;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
}

/* BERITA HOME */

.containerHomeHews {
  margin-top: -30px;
  background-color: #ffffff;
  z-index: 99;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3), 0 5px 5px rgba(0, 0, 0, 0.2);
}

.cardHome {
  float: left;
  padding: 0 1.7rem;
  width: 100%;
  height: 100%;
  margin: 10px 5px 10px 5px;
}

.cardHome .menu-content {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.cardHome .menu-content::before,
.cardHome .menu-content::after {
  content: "";
  display: table;
}

.cardHome .menu-content::after {
  clear: both;
}

.cardHome .menu-content li {
  display: inline-block;
}

.cardHome .menu-content a {
  color: #fff;
}

.cardHome .menu-content span {
  position: absolute;
  left: 50%;
  top: 0;
  font-size: 10px;
  font-weight: 700;
  font-family: "Open Sans";
  transform: translate(-50%, 0);
}

.cardHome .wrapper {
  background-color: #fff;
  min-height: 540px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3), 0 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.cardHome .wrapper:hover .data {
  transform: translateY(0);
}

.cardHome .data {
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(calc(70px + 1em));
  transition: transform 0.3s;
}

.cardHome .data .content {
  padding: 1em;
  position: relative;
  z-index: 1;
}

.cardHome .author {
  font-size: 12px;
}

.cardHome .title {
  margin-top: 10px;
}

.cardHome .text {
  height: 70px;
  margin: 0;
}

.cardHome input[type="checkbox"] {
  display: none;
}

.cardHome input[type="checkbox"]:checked + .menu-content {
  transform: translateY(-60px);
}

.example-1 .wrapper {
  background: url(https://images.unsplash.com/photo-1496979551903-46e46589a88b?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=cda12b505afa1beb06e49d89014cbd65&auto=format&fit=crop&w=634&q=80)
    20% 1% / cover no-repeat;
}

.example-1 .date {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #77d7b9;
  color: #fff;
  padding: 0.8em;
}

.example-1 .date span {
  display: block;
  text-align: center;
}

.example-1 .date .day {
  font-weight: 700;
  font-size: 24px;
  text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.18);
}

.example-1 .date .month {
  text-transform: uppercase;
}

.example-1 .date .month,
.example-1 .date .year {
  font-size: 12px;
}

.example-1 .content {
  background-color: #fff;
  box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.3);
}

.example-1 .title a {
  color: gray;
}

.example-1 .menu-button {
  position: absolute;
  z-index: 999;
  top: 16px;
  right: 16px;
  width: 25px;
  text-align: center;
  cursor: pointer;
}

.example-1 .menu-button span {
  width: 5px;
  height: 5px;
  background-color: gray;
  color: gray;
  position: relative;
  display: inline-block;
  border-radius: 50%;
}

.example-1 .menu-button span::after,
.example-1 .menu-button span::before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background-color: currentColor;
  position: absolute;
  border-radius: 50%;
}

.example-1 .menu-button span::before {
  left: -10px;
}

.example-1 .menu-button span::after {
  right: -10px;
}

.example-1 .menu-content {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  transition: transform 0.3s;
  transform: translateY(0);
}

.example-1 .menu-content li {
  width: 33.333333%;
  float: left;
  background-color: #77d7b9;
  height: 60px;
  position: relative;
}

.example-1 .menu-content a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}

.example-1 .menu-content span {
  top: -10px;
}

.rounded-10 {
  border-radius: 10px;
}

.cardMini {
  background-color: white;
  margin: 10px;
}

.rowHome {
  display: flex;
  margin: 10px;
}

.tab-custom {
  flex: 1;
  position: relative;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  border-bottom: 2px solid #818382;
}

.tab-custom.active {
  border-bottom: 2px solid #009a77;
}

/* .tab-custom.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ee0000;
}

.tab-custom:hover {
  background-color: lighten(var(--bg-color), 10%);
}

.tab-custom:hover::after {
  width: 100%;
}

.tab-custom:active {
  background-color: darken(var(--bg-color), 10%);
}

.tab-custom:active::after {
  background-color: darken(var(--bg-color), 20%);
} */

.card-news-home {
  box-shadow: 0 0 #0000, 0 0 #0000, 0px 6px 22px rgba(2, 43, 85, 0.08);
  border-radius: 10px;
  position: relative;
  top: -100px;
  min-width: 100%;
  min-height: 500px;
  z-index: 3;
}

.card-body-news-home {
  padding: 2rem;

  .title-news-home {
    color: var(--primary-custom) !important;
    font-size: 30px;
    /* font-weight: bold; */
    font-family: "Montserrat-Bold", sans-serif;
    text-transform: uppercase;
  }
}

.title-news-home {
  color: var(--primary-custom);
  font-size: 30px;
  /* font-weight: bold; */
  font-family: "Montserrat-Bold", sans-serif;
  text-transform: uppercase;
}

.btn-selengkapnya {
  border: 1px solid var(--primary-custom);
  border-radius: 10px;
  box-shadow: none;
  background-color: #fff;
  transition: 0.2s ease;
  font-weight: bold;
  color: var(--primary-custom);
}

.btn-selengkapnya:hover {
  background-color: var(--primary-custom);
  border: 1px solid var(--primary-custom);
  color: #fff;
  box-shadow: none;
}

.btn-selengkapnya:focus {
  box-shadow: none !important;
}

.gratifikasi {
  background-color: var(--text-warning);
}
